import * as React from 'react'
import classes from './About.module.scss'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import image1 from "../../assets/images/abTabStudent.jpg"
import Social from '../../components/Social/Social'
import bossImage from '../../assets/images/susan.jpg'
import extImage from '../../assets/images/kellerExterior.jpg'

export function TabContent(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={[classes.fully, classes.tabContent].join(" ")}>
          {children}
        </div>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function AboutTabs() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div
      className={[
        classes.fullSec,
        classes.tabStyles,
        classes.abTabs,
        classes.ilTabs,
        "ilTabs",
      ].join(" ")}
    >
      <div className={classes.tabLinks}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Stepping Stones Academy Information Tabs"
          className={classes.tabNav}
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Director" {...a11yProps(1)} />
          <Tab label="Staff &amp; Students" {...a11yProps(2)} />
          <Tab label="Our Facility" {...a11yProps(3)} />
          <Tab label="FAQs" {...a11yProps(4)} />
          <Tab label="News &amp; Updates" {...a11yProps(5)} />
        </Tabs>
      </div>

      <div className={[classes.fullSecXX, classes.tabBody].join(" ")}>
        <TabContent
          value={value}
          index={0}
          className={[classes.soloTab, classes.ovw].join(" ")}
        >
          <div className={classes.itWrap}>
            <h4>Ensuring Success, One Step at a Time</h4>
            <p>
              Stepping Stones Academy has been providing a safe and supportive environment
              for young children to grow, learn, and play since 2005. We believe that each child is unique, and 
              we are committed to developing learning opportunities that encourage curiosity, creativity, and 
              spontaneity. Our objective is to create a happy, warm, and disciplined setting for all of our students.
            </p>

            <p>
              We differentiate ourselves by providing you with outstanding, educational childcare, as well as peace of mind.
              You can hear the difference in our dedicated staff, as they speak with you about the
              classroom your child is/would be enrolled in. You can see the difference in our classroom sizes, the lowest 
              Staff:Child ratios in the area. And you can feel the difference in your heart as you connect with our vision 
              for loving and teaching your child.
            </p>
            

            <div className={[classes.fullSec, classes.group].join(" ")}>
              <div>
                <p className={classes.hdp}>Intellectual Growth</p>
                <p>
                  As child care professionals, our incredible staff understands the importance of the formative years of 
                  childhood. The valuable experiences and opportunities for first-time achievements for young children lay 
                  the foundation for higher learning. We teach skills for effective comprehension, communication, and
                  computation by providing a versatile curriculum. Our goal is to stimulate creative and critical 
                  thinking, and instill appreciation for life through the arts and sciences.
                </p>
              </div>
              <div>
                <p className={classes.hdp}>Social Growth</p>
                <p>
                  We encourage your child's sense of personal worth, while helping him/her establish wholesome, interpersonal
                  relationships. We strive to teach manners, fairness, courtesy, and acceptance of others, and  
                  work to instill a respect for authority, love of each other, and good citizenship to promote social responsibility.
                </p>
                
                <p className={classes.hdp}>Physical Development</p>
                <p>
                  We promote health, fitness, and coordination through various group 
                  activities, while also encouraging good sportsmanship.
                </p>
              </div>
            </div>
            
            <p className={classes.hdp}>Parental Involvement</p>
            <p>
              Stepping Stones Academy encourages your involvement and support throughout your
              child's journey at the Academy. We value our partnership with
              you, and are committed to the education and development of your child. On
              behalf of the staff at Stepping Stones, we look forward to
              helping meet your family's childcare needs, now and in the future.
            </p>
          </div>
        </TabContent>

        <TabContent
          value={value}
          index={1}
          className={[classes.soloTab, classes.boss].join(" ")}
        >
          <div className={classes.itWrap}>
            <p className={classes.lead}>Meet Our Director</p>
            <h4>Susan Porter</h4>

            <p>
              Susan Porter is a highly experienced childcare director with nearly 30 years of dedicated 
              service in the field. She has been at the helm of the Stepping Stones Academy team since 
              2005, leading with passion and committed to providing the best possible early education 
              for young children. In addition to her role at Stepping Stones Academy, Susan has also been 
              actively involved in the community, serving on many committees, including the CEIC Board for 
              Keller ISD. This demonstrates her strong dedication, not only to the children in her care, but 
              also to the broader educational community.
            </p>

            <div className={[classes.fullSec, classes.inline, classes.flexRowXX, classes.jbwXX].join(" ")}>
              <div className={classes.image}><img src={bossImage} alt="SSA Director, Susan Porter" /></div>
              <div className={classes.text}>
                <p>
                  Susan's devotion to her work is evident in the growth and success of Stepping Stones Academy. When 
                  the school first opened, her own children were just 4 years old and 8 months, and they have since grown 
                  up within the nurturing environment she has helped to create. Under Susan's leadership, Stepping Stones 
                  Academy was honored with the prestigious <span>Best of Fort Worth Award for Early Education in 2022.</span> This 
                  recognition is a testament to her goal-oriented and results-driven approach to early childhood education.
                </p>
              </div>
            </div>
            <div className={classes.clearfix} />
            <p>
              Overall, Susan Porter is a dedicated professional who is deeply committed to providing the best possible 
              education and care for young children. Her passion for early education, and her track record of success make 
              her highly respected. Susan is truly a testament to what it means to be a dedicated and impactful leader in her 
              community, and the field of early childhood education.
            </p>
          </div>
        </TabContent>

        <TabContent
          value={value}
          index={2}
          className={[classes.soloTab, classes.staff].join(" ")}
        >
          <div className={classes.itWrap}>
            <h4>Our Staff</h4>
            <p>
              From our skilled teachers, to our administrators and chef,
              Stepping Stones hires with our core vision in mind -- warm,
              responsive care coupled with a secure learning environment. Each
              staff member knows that the quality of the relationships your
              child experiences during their first 5 years of life has a deep
              and lasting impact on their future development. That knowledge
              guides them through every step of their day as they care for,
              enrich, and teach your child.
            </p>

            <h4>Our Students</h4>
            <p>
              Success is defined on many different levels at Stepping Stones Academy.
              Whether it is by an infant learning to clap their hands to music,
              a toddler becoming potty trained, a 3 year old sounding out words
              for the first time, a Pre Kindergartner reading a story with
              pride, or a Kindergartner having the self-confidence to present
              many projects at school, we love to witness and celebrate our students' successes! 
            </p>
            <p>
              Many of our “alumni” families return for visits, and tell us that
              the love for learning that their child developed while at the Academy
              gave them the knowledge, self-confidence, and pride they needed to succeed
              as they progressed in school. 
            </p>
            <p>
              Our local Elementary school teachers recognize when children have
              graduated from the Academy, and it is not uncommon for them to be
              placed in the Gifted and Talented Program.
            </p>
          </div>
        </TabContent>

        <TabContent
          value={value}
          index={3}
          className={[classes.soloTab, classes.facility].join(" ")}
        >
          <div className={classes.itWrap}>
            <h4>Our Facility</h4>
            <p>
              Our 8000 sq. ft. building provides both indoor and outdoor play
              areas, as well as large classrooms, with a maximum of 13 children
              per class.
            </p>
            <p>
              We strive to prioritize safety and cleanliness within our building and classrooms, without presenting a 
              sterile or commercial feel. We want to make your child feel as at-home as possible, and provide a comfortable and secure 
              environment that will only enrich their experience.
            </p>
            <div className={classes.image}><img src={extImage} alt="Stepping Stones Academy exterior view" /></div>
          </div>
        </TabContent>

        <TabContent
          value={value}
          index={4}
          className={[classes.soloTab, classes.faq].join(" ")}
        >
          <div className={classes.itWrap}>
            <h4>FAQs</h4>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>What are your hours of operation?</p>
              <p>
                Monday-Friday, 
                6:30am-6:30pm
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>What ages do you accept?</p>
              <p>
                6 weeks - Kindergarten
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Do you offer part time child care?</p>
              <p>
                Yes. Depending on enrollment and availability in your child's
                classroom, part time may be available. We offer part time in 2-4 full days, rather than half
                days, so that your child is able to participate and absorb as much curriculum and activity as
                possible. Rates vary based on the number of days your child attends.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Does your academy offer transportation to and from public school?</p>
              <p>
                No
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Are meals and snacks provided throughout the day?</p>
              <p>
                Yes. Children are provided with breakfast, lunch, and an afternoon
                snack. Infants and Walkers are given several snacks throughout the day.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Do all staff members receive training?</p>
              <p>
                Yes. All staff members are required to obtain CPR and first aid
                certification upon hiring, as well as every 2 years after. In addition
                to this, teachers are required to participate in 24 hours of
                annual training.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Does your academy offer online camera viewing?</p>
              <p>
                Yes and No. We do have cameras in every classroom that are
                available for viewing through the office. We do not, however,
                allow our cameras to be available online. We feel that this is the
                best way to ensure security and privacy for all of the children in
                our school.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>What curriculum does your academy use?</p>
              <p>
                We use the A Beka curriculum. A Beka is a Christian based
                curriculum that focuses on phonics, writing, math, arts and
                crafts, and basic Bible stories.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Who can pick up my child from school?</p>
              <p>
                You will complete a release authorization form upon enrollment where you will
                list the name and driver's license number for each person you authorize to pick up your child. 
                Any person(s) other than a child's parents will be required to present their driver's license,
                and allow us to make a copy before the child will be released into their care.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Does your school work with children on potty training?</p>
              <p>
                Yes. We begin working on potty training in our preschool classrooms.
                Because this is such a large transition for children, it requires a mutual effort at 
                home as well as at school. We want to work with the parents of the child to ensure
                the greatest ease and success in this process.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>How often do you clean the classrooms?</p>
              <p>
                Classrooms are cleaned throughout the day, as well as deep cleaned
                and sanitized every evening.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Are the children required to nap?</p>
              <p>
                No. We are required to offer a daily rest time. Our “nap time” is
                from 12:00 - 2:30pm. If your child does not nap, they will be
                able to do a quiet activity while others are napping.
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Are peanuts/nuts allowed in your school?</p>
              <p>
                <span className={classes.bold}>No. We are a nut free facility. Absolutely no peanut/nut products
                are allowed in the school.</span>
              </p>
            </div>
            <div className={classes.faqItem}>
              <p className={classes.hdp}>Other helpful safety information:</p>
              <p>
                Stepping Stones Academy receives emails from the Consumer Product Safety Commission when 
                any items have been recalled.  <a href="https://cpsc.gov" target="_blank" rel="noreferrer" className={classes.textLink}>Click here</a> to 
                visit their website, where you can also sign up for their news and updates.
              </p>
              <p>Poison Control: <a href="tel: +18002221222">1-800-222-1222</a></p>
            </div>
          </div>
        </TabContent>
  
        <TabContent
          value={value}
          index={5}
          className={[classes.soloTab, classes.news].join(" ")}
        >
          <div className={classes.itWrap}>
            <h4>News &amp; Updates</h4>
            <p>
              We feel that open communication with our families is essential. We
              want you to know what is happening in your child's classroom, and the
              school in general, and we offer several ways for you to stay informed.
            </p>
            <p>
              We use a service called Remind101 to send information via text message. Sign up to receive our 
              texts, and you will be notified of school-wide updates, as well as those specific to your child's class.
            </p>
            <p>
              The school voicemail will also have messages stating the current information for 
              early closing days, late openings, and other school closures.
            </p> 
            <p>
              You can follow Stepping Stones Academy on social media to stay up-to-date on all the 
              news and fun as well. We are always posting pictures of events, parties, and programs, as well as sharing 
              information about weather, early closings, and upcoming events.
            </p>
            <Social />
          </div>
        </TabContent>
      </div>
      
      <div className={classes.tabImg}><img src={image1} alt="Kindergarten boy coloring" className={classes.ti} /></div>
    </div>
  );
}