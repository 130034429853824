import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import classes from "./Home.module.scss"
import { WaveAlt } from "../../components/Wave/Wave"
import Review from "../../components/Review/Review"
import TourModal from '../../components/Modal/TourModal'
import as2 from '../../assets/images/homeIntro2.jpg'
import as2m from '../../assets/images/homeIntro2m.jpg'
import as3 from '../../assets/images/homeIntro3.jpg'
import as3m from '../../assets/images/homeIntro3m.jpg'
import letterImg from '../../assets/images/homeLetter.jpg'
import GroupHeart from '@mui/icons-material/Diversity1Outlined'
import Paint from '@mui/icons-material/ColorLensOutlined'
import Hat from '@mui/icons-material/SchoolOutlined'


export default function Home() {  
  const review1 = <p>"Stepping Stones Academy has been the biggest blessing for our little one, and our family!! They care, they go above and beyond, and they make sure each child is loved and so very well taken care of! Every staff member has their own gift, and we all are the recipients of their craft! Thank you Stepping Stones for loving us all so well!"</p>

  return (
    <div className={[classes.pageStyles, classes.Home].join(" ")}>
      <Helmet>
        <title>Early Educational Childcare in Keller, Texas | Stepping Stones Academy</title>
        <meta name="description" content="Stepping Stones Academy provides professional childcare and early education in Keller, Texas. Our program extends from infants to kindergarten." />
      </Helmet>

      <div className={[classes.inside, classes.stackOuter].join(" ")}>
        <div className={[classes.fullSec, classes.homeStack].join(" ")}>
          <div className={[classes.hlf, classes.mainHlf, classes.mhLeft, classes.borderr].join(" ")}>
            <div className={classes.textWrap}>
              <h1 className={classes.h1}>Exceptional Educational Childcare in Keller, TX</h1>
            </div>
          </div>

          <div className={[classes.hlf, classes.mainHlf, classes.mhRight, classes.borderl].join(" ")}>
            <div className={[classes.fullSec, classes.borderb, classes.rtop].join(" ")}>
              <div className={classes.text}>
                <h2 className={classes.h2}>
                  Nurturing, Teaching, and Encouraging 
                  <br/> Ages 6 Weeks - Kindergarten
                </h2>
                <Link to="/program" className={classes.ssLinkRev}>Our Program</Link>
              </div>

              <div className={classes.image}>
                <img src={as2} alt="Kindergarten student smiling, Stepping Stones Academy" className={classes.fw} />
                <img src={as2m} alt="Smiling student - Keller, TX" className={classes.mob} />
              </div>
            </div>

            <div className={[classes.fullSec, classes.bordert, classes.rtwo].join(" ")}>
              <div className={classes.text}>
                <p className={classes.hdline}>Ensuring Success,<br/> One Step at a Time</p>
                <TourModal btnStyle="ssLinkRev" />
              </div>

              <div className={classes.image}>
                <img src={as3} alt="Young children learning, Stepping Stones Academy" className={classes.fw} />
                <img src={as3m} alt="Children in classroom - Stepping Stones, Keller, TX" className={classes.mob} />
              </div>
            </div>
          </div>
        </div>
      </div>      
      
      <div className={[classes.fullSec, classes.inlineText].join(" ")}>
        <div className={classes.medDef}>
          <div className={[classes.flexRow, classes.textGroup].join(" ")}>
            <div className={classes.hd}>
              <p>Stepping Stones<br/> <span>Academy</span></p>
            </div>
            <div className={classes.content}>
              <p>
                Stepping Stones Academy has been the <span>premier</span> childcare facility in Keller, Texas <span>since 2005.</span> Our focus is to provide excellent 
                <span> childcare and education,</span> and to ensure the <span>intellectual</span> and <span>emotional growth</span> of your child. We 
                understand the importance of early learning, and the impact that it has on a <span>child's future.</span> Our <span>dedicated teachers</span> are 
                passionate about what they do, and committed to offering an academic program that <span>invokes curiosity</span> and <span>creativity,</span> and 
                ignites a <span>love of learning.</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.blocksRow].join(" ")}>
        <div className={[classes.inside, classes.blocksWrap].join(" ")}>
          <div className={[classes.block, classes.b1].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><Hat /></div>
            <p>Our versatile curriculum teaches skills for effective comprehension, communication, and computation.</p>
          </div>

          <div className={[classes.block, classes.b2].join(" ")}>
            <div className={[classes.icon, classes.art].join(" ")}><Paint /></div>
            <p>Our goal is to stimulate creative and critical thinking, and instill appreciation for life through the arts and sciences.</p>
          </div>

          <div className={[classes.block, classes.b3].join(" ")}>
            <div className={[classes.icon, classes.pack].join(" ")}><GroupHeart /></div>
            <p>We limit class enrollment to maintain a maximum student/teacher ratio of 13:1, the lowest in the area.</p>
          </div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.wave1].join(" ")}><WaveAlt color="color2" /></div>
      <div className={[classes.fullSec, classes.reviewBg].join(" ")}>
        <div className={classes.med2Def}>
          <Review review={review1} name="Marcia B." />
        </div>
      </div>
      <div className={[classes.fullSec, classes.wave2].join(" ")}><WaveAlt addClass="flip" color="color2" /></div>

      <div className={[classes.fullSec, classes.letter].join(" ")}>
        <div className={classes.medDef}>
          <p className={classes.fun}>Dear Parents,</p>
          <p>
            Thank you for considering Stepping Stones Academy for your early
            childcare needs. We are proud to offer a safe, healthy, and
            quality educational environment for your child. At Stepping
            Stones, we clearly understand that the different levels of
            development children pass through must coincide with changes in
            their care and education. That's why children at the Academy are
            treated as individuals, with specifically designed curriculum to
            match their developmental needs.
          </p>

          <div className={[classes.fullSec, classes.group].join(" ")}>
            <div className={classes.image}><img src={letterImg} alt="Children in classroom" /></div>
            <div className={classes.text}>
              <p> Our program provides an
                environment that fosters academic growth, encourages expression
                of creativity, introduces social skills, and develops cognitive
                thinking each step of the way in their early years.
              </p>
              <p>
                Our Academy believes strongly in the “Stepping Stones” approach
                of nurturing and education. This methodology encourages building
                on previously taught principles and values. Our staff of loving
                and experienced teachers showers attention on each child, and
                works diligently to ensure a caring and quality educational
                experience.
              </p>
            </div>
          </div>

          <p>
            At Stepping Stones, our objective is to provide your family with
            excellent childcare and education while also leaving you with
            peace of mind. We encourage parental involvement and support
            throughout your child's journey at the Academy. We value our
            partnership with you and in the education and development of
            your child.
          </p>
          <p>
            On behalf of the staff at Stepping Stones, we look forward to
            helping meet your family's childcare needs now and in the
            future.
          </p>
          <p className={classes.fin}>Sincerely, <br/>
            <span className={classes.fun}>Susan Porter, Director</span>
          </p>
        </div>
      </div>
    </div>
  );
}
