import * as React from 'react'
import classes from './Review.module.scss'

export default function Review(props) {
  return (
    <div className={[classes.fullSec, classes.review, classes[props.addClass ?? 'def']].join(' ')}>
      <div className={[classes.fullSec, classes.body].join(' ')}>{props.review}</div>
      <p className={classes.name}>- {props.name}</p>
    </div>
  )
}