import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import classes from "./Program.module.scss"
import TourModal from '../../components/Modal/TourModal'
import Review from "../../components/Review/Review"
import StackHeader from "../../components/StackHeader/StackHeader"
import hdImg from "../../assets/images/programHeader.jpg"
import { BtmWave } from '../../components/Wave/Wave'
import { Modal } from '../../components/Modal/Modal';
import blkInfants from "../../assets/images/programInfants.jpg"
import blkToddlers from "../../assets/images/programToddlers.jpg"
import blkPrek from "../../assets/images/programPrek.jpg"
import blkKindy from "../../assets/images/programK.jpg"


export default function Program() {
  const shText = (
    <div>
      <h2 className={classes.spread}>6 Weeks - Kindergarten</h2>
      <h1>The Stepping Stones <br/> Program &amp; Curriculum</h1>
      <p>
        We treat children like the <span>individuals</span> they are, with curriculum specifically designed to match their <span>developmental needs</span>. 
        We have created an environment that fosters <span>academic growth,</span> encourages <span>expression of creativity,</span> introduces <span>social 
          skills,</span> and develops <span>cognitive thinking</span> each step of the way.
      </p>
    </div>
  )

  const infantModal = (
    <div className={[classes.modBod, classes.infantMod].join(" ")}>
      <p>
        Our goal is to promote emotional, intellectual, social, and physical
        growth throughout your child's journey in the infant area. With your
        support, we hope these items of interest will help to make your child's
        transition a smooth and comfortable experience.
      </p>
      <p className={classes.lip}>
        Please bring a diaper bag with two complete changes of clothes. We try
        to take the children outside during nice weather and a sweater or
        jacket, and a hat may be appropriate.
      </p>
      <p className={classes.lip}>
        Label all belongings (i.e., bottles, bottle caps, jars, boxed and
        canned-food, clothing, cups, pacifiers, diaper bags, etc.). All bottles
        should be plastic. Formula and milk bottles must be prepared in advance.
      </p>
      <p className={classes.lip}>
        If your child is eating baby food, you may bring a daily or weekly
        supply of food. Teachers mark on the charts when more food is needed.
        The labeled food is kept in the cupboards until needed.
      </p>
      <p className={classes.lip}>
        When your child is ready for table food, Stepping Stones will provide a
        nutritious lunch.
      </p>
      <p className={classes.lip}>
        When your child is ready to use a sippy cup, it will be provided by
        Stepping Stones.
      </p>
      <p className={classes.lip}>
        The Director or designated person is responsible for administering any
        medications necessary. Please complete the medical authorization sheet.
        Medicines are stored in a medicine box, or in a special 
        box in the refrigerator, away from all food products.
      </p>

      <p className={classes.listp}>
        On your first day, we ask that you return your completed Infant's Daily
        Schedule, which includes the following information:
      </p>
      <ul>
        <li>How often he/she eats</li>
        <li>How much he/she eats at each sitting</li>
        <li>Whether or not he/she uses a pacifier</li>
        <li>Sleeping habits that are unique to him/her</li>
        <li>How he/she prefers being held when fussy</li>
        <li>If he/she is mobile and if so, does he/she sit, crawl, ect….</li>
        <li>If he/she like to swing</li>
        <li>A preference for ointment, if any is desired (A&D Creamy or Desitin</li>
        Creamy)
        <li>His/her napping schedule</li>
        <li>If he/she is not on a schedule and eats and sleeps on demand</li>
        <li>Any additional information that you feel is important</li>
      </ul>
    </div>
  )
  const twoThreeModal = (
    <div className={[classes.modBod, classes.twoThreeMod].join(" ")}>
      <p>
        Our goal is to promote emotional, intellectual, social, and physical
        growth throughout your child's journey in the 2 &amp; 3-year-old
        classes.
      </p>
      <p className={classes.lip}>
        We will be working on further color, letter, number, and shape
        recognition in our reading, math, science, and art classes. The children
        will also work on their fine motor skills in these areas by using
        different manipulatives available to them. We also use the ABeka curriculum for 2 &amp; 3's.
      </p>
      <p className={classes.lip}>
        Art classes allow the children the opportunity to express themselves in their own individual 
        way. We enhance their gross motor skills in music and creative movement classes. 
        We also begin introducing basic words in a foreign language with these age groups, as well as
        starting computer introduction.
      </p>
      <p className={classes.lip}>
        Naptime items may be stored in the room. Please check before leaving to
        be sure that you have your child's naptime item. We ask that naptime
        items be appropriately soft, cuddly, and labeled.
      </p>
      <p className={classes.lip}>
        A change of clothes, including a spare pair of shoes, socks, and
        underwear, should be brought every day, or left at Stepping Stones for
        the week. Please make sure that all items are labeled.
      </p>
      <p className={classes.lip}>
        The Director or designated person is responsible for administering any
        necessary medications. Please complete the medical authorization sheet
        if needed. Medicines are stored in a medicine box, or in a special 
        box in the refrigerator, away from all food products.
      </p>

      <hr/>
      <p className={classes.lip}><b>2 Year Olds:</b><br/>
        We will continue to work on potty training as the children mature. We encourage the children to wear pull-ups or underpants. There 
        is a lot of encouragement, stickers, and as always, many hugs and kisses for all their efforts.
      </p>
    </div>
  )
  const prekModal = (
    <div className={[classes.modBod, classes.prekMod].join(" ")}>
      <p>
        This is an exciting time in your four or five-year-olds life -- getting
        ready to go off to school for the first time! Our goal is to promote
        emotional, intellectual, social, and physical growth throughout your
        child's journey in his or her Pre-K class.
      </p>
      <p className={classes.lip}>
        We will be working with ABeka curriculum this year to further help your
        child prepare for Kindergarten next year. This curriculum will be
        based around reading, math, science, health, art classes, and music. The
        children will be working on their fine motor skills in these areas by
        using different manipulatives available to them.
      </p>
      <p className={classes.lip}>
        Their gross motor skills will be enhanced by their involvement in our
        music and creative movement class. Art class allows the children the
        opportunity to express themselves in their own individual way. Foreign
        language and computer class will also continue during this year.
      </p>
      <p className={classes.lip}>
        We will strive to achieve a rest period just once during the day, after
        lunch. However, if your child still needs to have a naptime, we will accommodate
        his or her needs. If they need a naptime item, please make sure it is
        appropriately soft, cuddly, and labeled.
      </p>
      <p className={classes.lip}>
        A change of clothing, including a spare pair of shoes, socks, and
        underwear should be brought every day, or left at Stepping Stones for
        the week. Please make sure that you have labeled all clothing.
      </p>
      <p className={classes.lip}>
        The Director or designated person is responsible for administering the
        medications necessary. Please complete the medical authorization sheet if needed.
        Medicines are stored in a medicine box, or in a special 
        box in the refrigerator, away from all food products.
      </p>
    </div>
  )
  const kindyModal = (
    <div className={[classes.modBod, classes.kindyMod].join(" ")}>
      <p>
        This is an exciting time in your five-year-olds life -- getting ready to
        start school for the first time! Our goal is to promote emotional,
        intellectual, social, and physical growth throughout your child's journey
        in his or her Kindergarten class.
      </p>

      <p className={classes.lip}>
        We will be working with ABeka curriculum this year to further help your
        child prepare for first grade next year. This curriculum
        will be based around reading, math, science, health, art, and music. The
        children will be working on their fine motor skills in these areas by
        using different manipulative available to them.
      </p>

      <p className={classes.lip}>
        Their gross motor skills will be enhanced by their involvement in our
        music and creative movement class. Art class allows the children the
        opportunity to express themselves in their own individual way. Our
        computer lab class will also continue during this year.
      </p>

      <p className={classes.lip}>
        We will strive to achieve a rest period just once during the day, after
        lunch. However, if your child still needs to have a naptime, we will accommodate
        his or her needs. If they need a naptime item, please make sure it is
        appropriately soft, cuddly, and labeled.
      </p>

      <p className={classes.lip}>
        A change of clothing, including a spare pair of shoes, socks and
        underwear should be brought every day, or left at Stepping Stones for
        the week, and please make sure that you have labeled all clothing.
      </p>

      <p className={classes.lip}>
        The Director or designated person is responsible for administering the
        medications necessary. Please complete the medical authorization sheet if needed.
        Medicines are stored in a medicine box, or in a special 
        box in the refrigerator, away from all food products.
      </p>
    </div>
  )

  const review = (
    <p>
      <span>"Stepping Stones is more than a school to us, they are an extension of our family. Our children have grown academically and socially and love going to school each day. The teachers love and care for our children as if they are their own. It is comforting to drop our children off with no worries every day, knowing they will be loved and well taken care of. We cannot say enough good things about Stepping Stones Academy. It is an amazing school!"</span>
    </p>
  )

  return (
    <div className={[classes.pageStyles, classes.Program, classes.tpad].join(" ")}>
      <Helmet>
        <title>Child Care and Education in Keller, TX | Stepping Stones Academy</title>
        <meta name="description" content="The Stepping Stones educational program fosters academic growth, creativity, social skills, and cognitive thinking to prepare your child for their future." />
      </Helmet>

      <StackHeader
        addClass="minor"
        where="shProgram"
        text={shText}
        image={hdImg}
        alt="Stepping Stones Kindergarten children laughing"
      />

      <div className={[classes.fullSec, classes.ps2].join(" ")}>
        <div className={[classes.med3Def, classes.ps2wrap].join(" ")}>
          <p>
            At 13:1, we maintain the lowest student/teacher ratio in the area. Our experienced teachers 
            guide your child through a curriculum based on the "Stepping Stones" approach, a 
            methodology built on previously taught principles and values. Read through the 
            information on this page to learn more about what we offer each age group. Follow 
            the links below if you would like to learn more about our academy, or request a tour.
          </p>
          <div className={[classes.fullSec, classes.btnRow].join(" ")}>
            <Link to="/about" className={[classes.ssLink, classes.ssLink2].join(' ')}>About Us</Link>
            <TourModal addClass="ssLink2" />
          </div>
        </div>
      </div>
      <div className={[classes.fullSec, classes.wave2].join(" ")}><BtmWave addClass="simple" color="lt3" /></div>

      <div className={[classes.fullSec, classes.ageBlocks].join(" ")}>
        <div className={[classes.inside, classes.abWrap].join(" ")}>
          <div className={[classes.block, classes.b1].join(" ")}>
            <div className={classes.image}><img src={blkInfants} alt="Infant at Stepping Stones Academy - Keller, TX daycare" /></div>

            <div className={classes.inBlock}>
              <h4>6 Weeks - <br/>23 Months</h4>
              <p>A loving and nurturing atmosphere to promote emotional, intellectual, social, and physical growth.</p>
              <Modal title="6 Weeks - 23 Months" content={infantModal} addBtnClass="ssLink2" />
            </div>
          </div>

          <div className={[classes.block, classes.b2].join(" ")}>
            <div className={classes.image}><img src={blkToddlers} alt="Toddler class at Stepping Stones Academy - Keller, TX" /></div>

            <div className={classes.inBlock}>
              <h4>2 &amp; 3 <br/>Year Olds</h4>
              <p>An environment designed for creative expression while developing problem solving, concept formation, and self-help skills.</p>
              <Modal title="2 & 3 Year Olds" content={twoThreeModal} addBtnClass="ssLink2" />
            </div>
          </div>

          <div className={[classes.block, classes.b3].join(" ")}>
            <div className={classes.image}><img src={blkPrek} alt="Happy pre K students in Keller, Texas" /></div>

            <div className={classes.inBlock}>
              <h4>Pre <br/>Kindergarten</h4>
              <p>An atmosphere that fosters group interaction to emphasize social skills, and build a foundation for academic success.</p>
              <Modal title="Pre Kindergarten" content={prekModal} addBtnClass="ssLink2" />
            </div>
          </div>

          <div className={[classes.block, classes.b4].join(" ")}>
            <div className={classes.image}><img src={blkKindy} alt="Stepping Stones kindergarten class doing work" /></div>

            <div className={classes.inBlock}>
              <h4>Private <br/>Kindergarten</h4>
              <p>Our teachers prepare your child's readiness for long term learning success utilizing the ABeka curriculum which, emphasizes language arts 
                skills, science, math, social studies, and Bible.
              </p>
              <Modal title="Kindergarten" content={kindyModal} addBtnClass="ssLink2" />
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.reviewSec].join(" ")}>
        <div className={[classes.inside, classes.inner].join(' ')}>
          <Review review={review} name="Ashley J." addClass="decor" />
        </div>
      </div>
    </div>
  );
}
