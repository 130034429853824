import { Helmet } from "react-helmet"
import classes from "./Careers.module.scss"
import StackHeader from "../../components/StackHeader/StackHeader"
import hdImg from "../../assets/images/careersHeader.jpg"
import {Indeed} from '../../components/Shared/Indeed'
import {BtmWave} from '../../components/Wave/Wave'
import Hail from '@mui/icons-material/HailOutlined'
import Read from '@mui/icons-material/LocalLibraryOutlined'
import Edit from '@mui/icons-material/EditNoteOutlined'
import Shake from '@mui/icons-material/HandshakeOutlined'
import Hat from '@mui/icons-material/SchoolOutlined'
import Seal from '@mui/icons-material/GppGoodOutlined'

export default function Careers() {
  const shText = (
    <h1>
      Join the Stepping Stones team, and embark on a <span>rewarding</span> career 
      in a <span>supportive, nurturing</span> environment that will <span>change your life, </span> 
      and the <span>lives of the children</span> you touch daily.
    </h1>
  )

  return (
    <div className={[classes.pageStyles, classes.Careers, classes.tpad].join(" ")}>
      <Helmet>
        <title>Start your Career in Childcare at Stepping Stones Academy</title>
        <meta name="description" content="A career at Stepping Stones Academy will change your life, and the lives of the children you touch daily." />
      </Helmet>

      <StackHeader
        where="shCareers"
        text={shText}
        image={hdImg}
        alt="Daycare teacher and student"
      />
      
      <div className={[classes.fullSec, classes.cs2].join(" ")}>
        <div className={classes.med2Def}>
          <p>
            Stepping Stones Academy is always looking for people with a huge heart that's full of love for children. If you 
            have a desire to impact children's lives in a way that will change them forever, follow the link below to 
            see the positions we currently have available.
          </p>
          <a href={Indeed} target="_blank" rel="noreferrer" className={classes.ssLink}>Job Openings</a>
        </div>
      </div>
      <div className={[classes.fullSec, classes.wave2].join(" ")}><BtmWave addClass="simple" color="lt3" /></div>


      <div className={[classes.fullSec, classes.blocksRow].join(" ")}>
        <div className={classes.titleRow}><h3>What Makes You a Good Fit?</h3></div>
        <div className={[classes.inside, classes.blocksWrap].join(" ")}>
          <div className={[classes.block, classes.b2].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><Hat /></div>
            <p>Demonstrate knowledge and ability to lead staff in implementing developmentally appropriate curriculum</p>
          </div>

          <div className={[classes.block, classes.b3].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><Read /></div>
            <p>Must be familiar with Texas Minimum Standards for Child Care, and committed to upholding them</p>
          </div>

          <div className={[classes.block, classes.b6].join(" ")}>
            <div className={[classes.icon, classes.life].join(" ")}><Shake /></div>
            <p>Establish positive, respectful, and professional relationships with children, parents, and co-workers</p>
          </div>

          <div className={[classes.block, classes.b7].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><Edit /></div>
            <p>Demonstrate outstanding written and oral communication</p>
          </div>

          <div className={[classes.block, classes.b1].join(" ")}>
            <div className={[classes.icon, classes.life].join(" ")}><Hail /></div>
            <p>Organized, self-motivated, flexible, and willing to learn</p>
          </div>

          <div className={[classes.block, classes.b9].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><Seal /></div>
            <p>Clean background check </p>
          </div>
        </div>
      </div>
    </div>
  )
}
