import React, {Component} from 'react'
import { Link } from "react-router-dom"
import classes from "./Header.module.scss"
import PayPdf from '../../TuitionAuthorization.pdf'
import Enroll from '../../SSA-Enrollment-Application.pdf'
import ParentRights from '../../ParentRights.pdf'
import TourModal from '../Modal/TourModal'
import DownIcon from '@mui/icons-material/KeyboardArrowDown'

export const AuxNav = (props) => {
  return (
    <>
      <li className={[classes[[props.class1 ?? 'navItem']], classes[[props.groupClass ?? 'aux']], classes.tour].join(' ')}>
        <TourModal btnStyle="navLink" />
      </li>
      <li className={[classes[[props.class1 ?? 'navItem']], classes[[props.groupClass ?? 'aux']], classes.job].join(' ')}><Link to="/careers">Careers</Link></li>
    </>
  )
}

// MAY NEED if enroll drop adds pages, not just pdf 
// export function ClickAway() {
//   const [anchorEl, setAnchorEl] = React.useState(null)
//   const open = Boolean(anchorEl)
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget)
//   }
//   const handleClose = () => {
//     setAnchorEl(null)
//   }

//   return (
//     <MenuItem><Link to="/" onClick={handleClose}>Click</Link></MenuItem>
//   )
// }

export const EnrollList = (props) => {
  return (
    <ul className={classes[[props.ulClass ?? 'dropMenu']]}>
      <li className={[classes.linkClass, classes[[props.class1 ?? 'dropItem']]].join(' ')}><a href={Enroll} target="_blank" rel="noreferrer">Enrollment Application</a></li>
      {props.noParent === true ? null : (
        <li className={[classes.linkClass, classes[[props.class1 ?? 'dropItem']]].join(' ')}><a href={ParentRights} target="_blank" rel="noreferrer">Parent's Rights</a></li>
      )}
      <li className={[classes.linkClass, classes[[props.class1 ?? 'dropItem']]].join(' ')}><a href={PayPdf} target="_blank" rel="noreferrer">Tuition Autopay</a></li>
    </ul>
  )
}
class NavItems extends Component {
  render() {
    return(
      <ul className={[classes[this.props.navClass], classes[this.props.addClass]].join(' ')}>
        {this.props.hideHome !== true ? <li className={[classes.navItem, classes.ihome].join(' ')}><Link to="/">Home</Link></li>: null}
        <li className={[classes.navItem, classes.about].join(' ')}><Link to="/about">About Us</Link></li>
        <li className={[classes.navItem, classes.prog].join(' ')}><Link to="/program">Our Program</Link></li>

        {this.props.navClass === "nav" ? (
          <li className={[classes.navItem, classes.parent].join(' ')}>
            <Link to="#" className={classes.topLink}>Enrollment &amp; Tuition <span className={classes.dropIcon}><DownIcon /></span></Link>
            <div className={classes.dropWrap}>
              <EnrollList />
            </div>
          </li>
        ) : null}
        <li className={[classes.navItem, classes.res].join(' ')}><Link to="/resources">Resources</Link></li>
        <li className={[classes.navItem, classes.contact].join(' ')}><Link to="/contact">Contact</Link></li>

        {this.props.hideAux === true ? null : <AuxNav groupClass="mobOnly" />}

        {this.props.navClass === "drawerNav" ? (
          <EnrollList ulClass="enrollLi" />
        ) : null}
       
      </ul>
    );
  }
}
export default NavItems;