import { Helmet } from "react-helmet"
import classes from "./Contact.module.scss"
import ContactForm from "../../components/Form/Form"
import Phone from "../../components/Shared/Phone"
import Email from "../../components/Shared/Email"
import {Address} from '../../components/Shared/Address'
import Social from "../../components/Social/Social"
import StackHeader from "../../components/StackHeader/StackHeader"
import hdImg from "../../assets/images/contactHeader.jpg"
import Review from "../../components/Review/Review"

export default function Contact() {
  const shText = (
    <div className={[classes.flexRow, classes.jbw, classes.ac, classes.mainFr].join(' ')}>
      <h1>Stepping Stones Academy</h1>
      <div className={classes.fr2}>
        <div className={[classes.flexRow, classes.subFr].join(' ')}>
          <div className={[classes.fcItem, classes.ip].join(' ')}><p><Phone /></p></div>
          <div className={[classes.fcItem, classes.is].join(' ')}><Social /></div>
        </div>
        <div className={[classes.fcItem, classes.ie].join(' ')}><p><Email /></p></div>
        <div className={[classes.fcItem, classes.ia].join(' ')}><Address showBtn={true} btnStyle="textLink" altStyle={true} /></div>
        <div className={[classes.fcItem, classes.ih].join(' ')}><p>Open Monday-Friday <span className={[classes.dot, classes.dot1].join(' ')}></span> 6:30am-6:30pm</p></div>
      </div>
    </div>
  )

  const review = (
    <p>
      <span>"We absolutely LOVE Stepping Stones Academy! Our daughter has been in school here since she was 3 months old and I wish we could keep her here until she graduates from high school. The teachers are wonderful and they sincerely care about each of their students. The administration is very involved in the school and with the parents and students. I honestly couldn't recommend this school more to parents looking for the perfect place for their child(ren). And as much as we love SSA, our daughter loves it just as much. Thank you SSA for a wonderful early childhood experience!!"</span>
    </p>
  )
  return (
    <div className={[classes.pageStyles, classes.Contact, classes.tpad].join(" ")}>
      <Helmet>
        <title>Request a Tour of our Daycare/Kindergarten | Stepping Stones Academy</title>
        <meta name="description" content="Contact Stepping Stones Academy today to set up a tour of Keller, Texas's premier daycare/kindergarten." />
      </Helmet>

      <StackHeader
        where="shContact"
        text={shText}
        image={hdImg}
        alt="Child playing with toy phone"
      />
  
      <div className={[classes.fullSec, classes.formsec].join(" ")}>
        <div className={classes.med3Def}>          
          <ContactForm />
        </div>
      </div>

      <div className={[classes.fullSec, classes.reviewSec].join(" ")}>
        <div className={[classes.insideTw, classes.inner].join(' ')}>
          <Review review={review} name="Courtney C." addClass="decor" />
        </div>
      </div>
    </div>
  )
}
