import * as React from 'react'
import classes from './BackTop.module.scss'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import scrollSmoothTo from './Smooth'
import Box from '@mui/material/Box'
import Up from '@mui/icons-material/ArrowUpward'
import Fade from '@mui/material/Fade'

export function BackToTop() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
  })

  const handleClick = (e) => {
    const anchor = (
      (e).ownerDocument || document
    ).querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      })
    }
  }

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
      >
        <button onClick={() => scrollSmoothTo('ssa-top')} title="Click to scroll to top" className={classes.upBtn}><Up /></button>
      </Box>
    </Fade>
  );
}