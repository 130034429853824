import React, {Component} from 'react'
import classes from "./Footer.module.scss"
import NavItems, {AuxNav, EnrollList} from "../Header/NavItems"
import Phone from "../Shared/Phone"
import Email from "../Shared/Email"
import {Address} from '../Shared/Address'
import Social from "../Social/Social"
import { TopWave } from '../Wave/Wave'

class Footer extends Component {
  render() {
    return (
      <div className={[classes.fullSec, classes.footWrap].join(' ')}>
        <footer className={[classes.fullSec, classes.footerSec].join(' ')}>
          <div className={classes.wave1}><TopWave /></div>

          <div className={[classes.fullSec, classes.top].join(' ')}>
            <div className={[classes.inside, classes.topWrap].join(' ')}>
              <div className={[classes.ftGroup, classes.menu, classes.menu1].join(' ')}>
                <NavItems hideAux={true} />
              </div>

              <div className={[classes.ftGroup, classes.contact].join(' ')}>
                <div className={[classes.fcItem, classes.ip].join(' ')}><Phone /></div>
                <div className={[classes.fcItem, classes.ie].join(' ')}><Email /></div>
                <div className={[classes.fcItem, classes.ia].join(' ')}><Address altStyle={true} showBtn={false} /></div>
                <div className={[classes.fcItem, classes.is].join(' ')}><Social /></div>
              </div>

              <div className={[classes.ftGroup, classes.menu, classes.menu2].join(' ')}>
                <ul><AuxNav /></ul>
                <EnrollList noParent={true} />
              </div>
            </div>
            {/* *Please remember, our school is a Peanut/Tree Nut free facility. This means that some children in our school are allergic to peanuts/tree nuts and may have serious reactions to those items. Please do not send any items to the school with these ingredients. We want all children to be, and stay, safe and healthy! */}
          </div>

          <div className={[classes.fullSec, classes.btm].join(' ')}>
            <div className={classes.med3Def}>
              <p className={[classes.copy].join(" ")}>
                © {new Date().getFullYear()} Stepping Stones Academy. All rights reserved. <br/>Design by{" "}
                <a
                  href="http://hsdesignhouse.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  High Seas Design House.
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
export default Footer;