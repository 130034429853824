import classes from './Social.module.scss'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'

export const fbLink = ("https://facebook.com/steppingstoneskeller")
export const instaLink = ("https://instagram.com/steppingstoneskeller")

const social = (props) => {
  return (
    <div className={[classes.Social, classes[props.where], classes[props.addClass ?? 'reg']].join(' ')}>
      <div className={[classes.sitem, classes.fb].join(' ')}><a href={fbLink} target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="Stepping Stones Academy Facebook Link"><FacebookIcon /></span>
      </a></div>

       <div className={[classes.sitem, classes.ig].join(' ')}><a href={instaLink} target="_blank" rel="noopener noreferrer">
        <span aria-label="Stepping Stones Academy Instagram Link"><InstagramIcon /></span>
      </a></div>
    </div>
  )
}
export default social;