import { Helmet } from "react-helmet"
import classes from "./Resources.module.scss"
import StackHeader from "../../components/StackHeader/StackHeader"
import { TopWave, BtmWave } from '../../components/Wave/Wave'
import Review from "../../components/Review/Review"
import hdImg from '../../assets/images/resourcesHeader.jpg'

export default function Resources() {
  const shText = (
    <>
      <h1>Helpful <span>Educational<br/> Resources</span> for Parents</h1>
      <p>
      The list below contains links to some of our favorite educational resources for families with young children. 
      Explore the links provided for information and tips on parenting, childhood education and development, safety, and other useful topics.
      </p>
    </>
  )
  const review = (
    <p>
      <span>"We love Stepping Stones! My daughter is very selective with who she trusts. Over the past 3 years, everyone on the staff has earned a place in her trusted category. When we had our son, it was a no-brainer where we would send him. I trust them like family, and know my kids are more than just taken care of during the day, they are truly loved. I have no hesitation when people ask for recommendations on daycares to send them to Stepping Stones!"</span>
    </p>
  )

  return (
    <div className={[classes.fullSec, classes.Resources].join(' ')}>
      <Helmet>
        <title>Tips & Information for Parents of Stepping Stones Academy</title>
        <meta name="description" content="We have compiled a list of links to tips and information on parenting, childhood education, and other useful topics for families." />
      </Helmet>

      <StackHeader
        where="shResources"
        text={shText}
        image={hdImg}
        alt="Mother walking kids to school"
      />

      <div className={[classes.fullSec, classes.infoList].join(" ")}>
        <div className={[classes.fullSec, classes.waveAbs].join(" ")}>
          <div className={[classes.wave, classes.wave1].join(" ")}><TopWave addClass="simple" color="lt3" /></div>
          <div className={[classes.wave, classes.wave2].join(" ")}><BtmWave addClass="simple" color="lt3" /></div>
        </div>

        <div className={[classes.inside, classes.ilWrap].join(" ")}>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://getparentingtips.com" target="_blank" rel="noreferrer">Parenting Tips from Texas Department of Family and Protective Services</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://earlychildhood.texas.gov/at_home_learning.html" target="_blank" rel="noreferrer">Parenting, Child Safety, and Learning Tips from Early Childhood Texas</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://www.hhs.texas.gov/services/disability/early-childhood-intervention-services/information-families" target="_blank" rel="noreferrer">Early Childhood Intervention Information from Texas Health and Human Services</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://www.cdc.gov/ncbddd/actearly/milestones/index.html" target="_blank" rel="noreferrer">CDC's Developmental Milestones</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href=" https://www.mhmrtarrant.org/" target="_blank" rel="noreferrer">Health Resources of Tarrant County</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://www.txabusehotline.org/Login/Default.aspx" target="_blank" rel="noreferrer">Texas Abuse Hotline</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://www.sleepfoundation.org/children-and-sleep/sleep-strategies-kids" target="_blank" rel="noreferrer">Sleep Strategies for Kids</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://kidshealth.org/en/parents/self-control.html" target="_blank" rel="noreferrer">Teaching Your Child Self-Control</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://kidshealth.org/en/parents/self-esteem.html" target="_blank" rel="noreferrer">Your Child's Self-Esteem</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://www.breastmilkcounts.com" target="_blank" rel="noreferrer">Breastfeeding Information and Support</a></p></div>
          <div className={[classes.ilItem, classes.ix].join(" ")}><p><a href="https://www.savemewithaseat.org" target="_blank" rel="noreferrer">Car Seat Safety Tips</a></p></div>
          <div className={[classes.ilItem, classes.cta].join(" ")}><p>Check back for new items!</p></div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.reviewSec].join(" ")}>
        <div className={[classes.inside, classes.inner].join(' ')}>
          <Review review={review} name="Ashley W." addClass="decor" />
        </div>
      </div>
    </div>
  );
}
