import * as React from 'react'
import classes from './Header.module.scss'
import NavItems from './NavItems'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import Social from '../Social/Social'
import Email from '../Shared/Email'
import Phone from '../Shared/Phone'
import {Address} from '../Shared/Address'

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  }

  const emailBr = (
    <>staff@<br/> &nbsp;&nbsp;thesteppingstonesacademy.com</>
  )

  const navList = side => (
    <div
      className={[classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><CloseIcon /></div>
      <NavItems navClass="drawerNav" />

      <ul className={[classes.fullSec, classes.coninfo].join(' ')}>
        <li className={[classes.item, classes.ip].join(' ')}><Phone /></li>
        <li className={[classes.item, classes.ia].join(' ')}><Address btnStyle="textLink" /></li>
        <li className={[classes.item, classes.ie].join(' ')}><Email text={emailBr} /></li>
        <li className={[classes.item, classes.is].join(' ')}><Social /></li>
      </ul>
    </div>
  )

  return (
    <div className={[classes.NavDrawer].join(' ')}>
      <button onClick={toggleDrawer('left', true)} className={classes.menuBtn}>
        <MenuIcon />
      </button>

      <SwipeableDrawer
        anchor='left'
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
        className={[classes.cNavDrawer, "cNavDrawer"].join(' ')}
      >
        {navList('left')}
      </SwipeableDrawer>
    </div>
  );
}