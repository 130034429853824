import classes from './Shared.module.scss'

export function Directions(props) {
  return (
    <a
      href="https://www.google.com/maps/dir//11655+Alta+Vista+Rd,+Keller,+TX+76244/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x864dd9edc535eed9:0x666fc1a1e769c5a4?sa=X&ved=1t:707&ictx=111"
      target="_blank"
      rel="noreferrer"
      className={[classes[props.styled ?? 'ssLinkSm'], classes[props.addClass ?? "std"]].join(' ')}
      type="button"
      title="Get directions to Stepping Stones Academy"
    >
      {props.btnText ? [props.btnText] : "Get Directions"}
    </a>
  )
}

export function Address(props) {
  let btnStyle = props.btnStyle ?? 'ssLinkSm'
  let showBtn = true
  if (props.showBtn === false) {
    btnStyle = 'nobtn'
    showBtn = false
  }
  let spaceClass = `v${btnStyle}`

  return (
    <div className={[classes.addr, classes[props.addClass ?? 'classic']].join(' ')}>
      {props.addText ? 
        <>
          [props.addText]
          <br className={classes.cbr}/>
        </>
        : null
      }
      {props.address ? props.address 
        : props.altStyle ? (
            <p className={classes[spaceClass]}>
              11655 Alta Vista Rd <span className={[classes.dot, classes.dot1].join(' ')}></span> Keller, TX 76244
            </p>
        ) : (
          <p className={classes[spaceClass]}>11655 Alta Vista Rd <br className={classes.br1} /> Keller, TX 76244</p>
        )
      }
      {showBtn === true && (
        <div className={classes.clearfixBtn}>
          <Directions
            styled={btnStyle}
            addClass={props.btnClass ?? "std"}
            btnText={props.btnText ?? null}
          />
        </div>
      )}
    </div>
  )
}