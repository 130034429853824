import { Component } from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.scss'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import { BackToTop } from './components/Smooth/BackTop'
import withRouter from './components/Functionals/WithRouter.tsx'
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Program from './Pages/Program/Program'
import Contact from './Pages/Contact/Contact'
import Careers from './Pages/Careers/Careers'
import Resources from './Pages/Resources/Resources'


class App extends Component {
  render() {
    let routes = (
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/program" element={<Program />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/resources" element={<Resources />} />
      </Routes>
    )

    const HeadStyle = () => {
      if (window.location.pathname === '/') {
        return (<Header version="topbar" />)
      }
      return (<Header version="btmbar" />)
    }
    const SneakyHeader = withRouter(HeadStyle)

    return (
      <div id="ssa-top" className="ssWrap fullSec">
        <SneakyHeader />
        <main className="mainWrap">
          {routes}
        </main>
        <Footer />
        <BackToTop />
      </div>
    )
  }
}
export default App