import React, {Component} from 'react'
import { Link } from "react-router-dom"
import classes from "./Header.module.scss"
import NavItems, {AuxNav} from "./NavItems"
import Drawer from "./Drawer"
import Phone from '../Shared/Phone'
import Email from '../Shared/Email'
import logoEps from "../../assets/images/logoEps.png"

export const InfoBar = (props) => (
  <div className={[classes.fullSec, classes.infoRow, classes[props.which]].join(' ')}>
    <div className={classes.inside}>
      <div className={[classes.flexRow, classes.trFlex, classes.jbw].join(' ')}>
        <div className={[classes.trGroup, classes.info].join(' ')}>
          <div className={[classes.trItem, classes.phone].join(' ')}><Phone where="topbar" /></div>
          <div className={[classes.trItem, classes.email].join(' ')}><Email where="topbar" /></div>
        </div>

        <div className={[classes.trGroup, classes.links].join(' ')}>
          <ul className={[classes.auxNav, classes.none].join(' ')}>
            <AuxNav class1="auxLi" groupClass="trItem" />
          </ul>
        </div>
      </div>
    </div>
  </div>
)

class Header extends Component {
  render() {
    const vclass = `v${this.props.version}`
    return (
      <div className={[classes.headerSec, classes[vclass], classes.longNav].join(' ')}>
        {this.props.version === 'topbar' || this.props.version === null ?
          (<InfoBar which="topbar" />)
          : ''
        }
        <header className={classes.mainHeader}>  
          <div className={[classes.medDef, classes.mhWrap].join(' ')}>

            <div className={[classes.fullSec, classes.navRow].join(' ')}>
              <div className={[classes.nrItem, classes.logo].join(' ')}>
                <Link to="/" className={classes.logoA}><img src={logoEps} alt="Stepping Stones Academy Logo" /></Link>
              </div>
              <div className={[classes.mobNav, classes.xxx].join(' ')}><Drawer /></div>
              <nav className={[classes.nrItem, classes.navWrap].join(' ')}>
                <NavItems where="header" navClass="nav" hideHome={true} />
              </nav>
            </div>
          </div>
        </header>
        {this.props.version === 'btmbar' ?
          (<InfoBar which="btmbar" />)
          : ''
        }
      </div>
    );
  }
}
export default Header;