import * as React from 'react'
import classes from './TourModal.module.scss'
import './TourModal.module.scss'
import Dialog from '@mui/material/Dialog'
import {TourForm} from "../../components/Form/Form";

export default function TourModal(props) {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={[classes.tourModalFull].join(' ')}>
      <button className={[classes.tmLink, classes[props.btnStyle ?? 'ssLink'], classes[props.addClass ?? 'def']].join(' ')} onClick={handleClickOpen}>
        {props.btnText ?? 'Request a Tour'}
      </button>

      <Dialog
        fullWidth={true}
        maxWidth="tour"
        open={open}
        onClose={handleClose}
        className={[classes.tourModalWrap].join(' ')}
      >
        <div className={[classes.fullSec, classes.modalBody].join(' ')}>
          <button onClick={handleClose} className={classes.modalClose}>x</button>
          <div className={[classes.inside, classes.tmInner].join(' ')}>
            <div className={[classes.fullSec, classes.top].join(' ')}>
              <h4>Request a Tour</h4>
              <p>Fill out the form below, and someone from our office will contact you as soon as possible to set up your appointment.</p>
            </div>
            <TourForm />
          </div>
        </div>
      </Dialog>
    </div>
  )
}