import React, { useState } from 'react'
import classes from './Modal.module.scss'


export function Modal(props) {
  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => {
    setIsOpen(true)
    document.body.style.overflow = "hidden";
  }
  const closeModal = () => {
    setIsOpen(false)
    document.body.style.overflow = "auto";
  }
  return (
    <div className={[classes.cmModalGroup, classes[props.modalClass ?? 'cmModalFull']].join(' ')}>
      <button onClick={openModal} className={[classes[props.btnStyle ?? 'ssLinkSm'], classes[props.addBtnClass ?? 'modLink']].join(' ')}>{props.btnText ?? 'Learn More'}</button>
      {isOpen && (
        <div className={[classes.cmOverlay, classes[props.addClass ?? 'default']].join(' ')}>
          <div className={classes.cmContent}>
            <span className={classes.modalClose} onClick={closeModal}>&times;</span>
            <div className={[classes.cmWrap, classes[props.wrapClass ?? 'inside']].join(' ')}>
              {props.title ? <div className={classes.modalTitle}><h4>{props.title}</h4></div> : null}
              {props.content}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

