import * as React from "react"
import classes from "./StackHeader.module.scss"

export default function StackHeader(props) {
  return (
    <div
      className={[
        classes.fullSec,
        classes.stackHeader,
        classes[props.where ?? "genStyle"],
        classes[props.addClass ?? 'major']
      ].join(" ")}
    >
      <div className={[classes.inside, classes.stackWrap].join(" ")}>
        <div className={[classes.ilitem, classes.image].join(" ")}>
          <img src={props.image} alt={props.alt} className={classes.fwv} />
        </div>

        <div className={[classes.ilitem, classes.text].join(" ")}>
          {props.text}
        </div>
      </div>
    </div>
  )
}
