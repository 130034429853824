// import React from "react";
import React, {Component} from 'react';
import classes from "./Shared.module.scss";
import appStyles from "../../App.scss";

class Email extends Component {
  render() {
    let styleParent = classes;
    if (this.props.styleParent === 'appStyles') {
      styleParent = appStyles;
    }
    return (
      <a 
        className={[styleParent.emailLink, styleParent[this.props.where]].join(' ')} href={"mailto:" + this.props.addr}>
        {this.props.text ? [this.props.text] : [this.props.addr]}
      </a>
    )
  }
}
Email.defaultProps = {
  addr: "staff@thesteppingstonesacademy.com"
}
export default Email;