import classes from './Form.module.scss'

export default function ContactForm() {
  return(
    <div className={[classes.fullSec, classes.formWrap].join(' ')}>
      <form action="https://formspree.io/f/xdoqjoze" method="POST" className={[classes.Form, classes.conform].join(' ')}>
        <input
          type="hidden"
          name="_subject"
          id="email-subject"
          value="SSA Contact Form Submission"
        ></input>
        <input
          type="hidden"
          name="_cc"
          value="ryan@clearcapitalpartners.com"
        />
        <div className={[classes.formGroup, classes.name].join(' ')}>
          <label>Name:</label>
          <input type="text" name="Name" />
        </div>
        
        <div className={[classes.formGroup, classes.combo].join(' ')}>
          <div className={[classes.comitem, classes.email].join(' ')}>
            <label>Email:</label>
            <input type="email" name="Email" />
          </div>
          
          <div className={[classes.comitem, classes.phone].join(' ')}>
            <label>Phone:</label>
            <input type="phone" name="Phone" />
          </div>
        </div>
        
        <div className={[classes.formGroup, classes.i].join(' ')}>
          <label>How can we help?</label>
          <textarea name="Message" />
        </div>
        
        <div className={[classes.formGroup, classes.i].join(' ')}>
          <button type="submit" className={classes.ssLink}>Submit</button>
        </div>
      </form>
    </div>
  )
}

export function TourForm() {
  return(
    <div className={[classes.fullSec, classes.formWrap].join(' ')}>
      <form action="https://formspree.io/f/xdoqjoze" method="POST" className={[classes.Form, classes.tourform].join(' ')}>
        <input
          type="hidden"
          name="_subject"
          id="email-subject"
          value="SSA Tour Request Form Submission"
        ></input>
        <input
          type="hidden"
          name="_cc"
          value="ryan@clearcapitalpartners.com"
        />
        
        <div className={[classes.formGroup, classes.combo].join(' ')}>
          <div className={[classes.comitem, classes.name].join(' ')}>
            <label>Name:</label>
            <input type="text" name="Name" required="true" />
          </div>
          
          <div className={[classes.comitem, classes.name2].join(' ')}>
            <label>Child's Name:</label>
            <input type="text" name="Child's name" required="true" />
          </div>
        </div>
        
        <div className={[classes.formGroup, classes.combo].join(' ')}>
          <div className={[classes.comitem, classes.email].join(' ')}>
            <label>Email:</label>
            <input type="email" name="Email" required="true" />
          </div>
          
          <div className={[classes.comitem, classes.phone].join(' ')}>
            <label>Phone:</label>
            <input type="phone" name="Phone" required="true" />
          </div>
        </div>

        <div className={[classes.formGroup, classes.combo].join(' ')}>
          <div className={[classes.comitem, classes.dob].join(' ')}>
            <label>Child's Date of Birth:</label>
            <input type="text" name="Child DOB" required="true" />
          </div>
          
          <div className={[classes.comitem, classes.av].join(' ')}>
            <label>Available Days/Times for Tour:</label>
            <input type="text" name="Availability" required="true" />
          </div>
        </div>
        
        <div className={[classes.formGroup, classes.i].join(' ')}>
          <button type="submit" className={classes.ssLink}>Submit</button>
        </div>
      </form>
    </div>
  )
}