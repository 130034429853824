import { Helmet } from "react-helmet"
import classes from "./About.module.scss"
import AboutTabs from "./AboutTabs"
import StackHeader from "../../components/StackHeader/StackHeader"
import hdImg from '../../assets/images/aboutHeader.jpg'
import { TopWave, BtmWave } from "../../components/Wave/Wave"
import Review from "../../components/Review/Review"
import Hail from '@mui/icons-material/HailOutlined'
import GroupHeart from '@mui/icons-material/Diversity1Outlined'
import Lesson1 from '@mui/icons-material/AssignmentOutlined'
import Paint from '@mui/icons-material/ColorLensOutlined'
import Shake from '@mui/icons-material/HandshakeOutlined'
import School from '@mui/icons-material/ApartmentOutlined'
import Award from '@mui/icons-material/WorkspacePremiumOutlined'
import Phone from '@mui/icons-material/SmartphoneOutlined'
import Love from '@mui/icons-material/FavoriteBorderOutlined'


export default function About() {
  const shText = (
    <h1>
      Stepping Stones Academy provides premier <span>childcare</span> and <span>early 
      education</span> for ages<br /> <span>6 weeks - Kindergarten.</span>
    </h1>
  )

  const mission = (
    <div className={[classes.fullSec, classes.missionSec].join(" ")}>
      <div className={[classes.medDef, classes.ibgWrap].join(" ")}>
        <h3>Mission Statement</h3>
        <p>We pledge to make the world a better place through young children; to love every child in our care; to educate children in all four areas of development: physical, social, emotional, and intellectual; to build a solid reputation in the community for doing all the above.</p>
      </div>
    </div>
  )

  const review1 = <p>
      <span>"We have been with Stepping Stones Academy for 6 years with our daughter just recently graduating to go to kindergarten and now have our son there. They are amazing! They truly care about the kids there and are always looking out for their best interest. They teach them so much from a very young age. Their teachers are the best and many have been there for a long time which makes it great to know your kids are in good hands with both my kids having several of the same teachers. I wouldn't want them anywhere else…They're like family!"</span>
    </p>

  return (
    <div className={[classes.pageStyles, classes.About, classes.tpad].join(" ")}>
      <Helmet>
        <title>Leading Childcare Program in Keller, Texas | Stepping Stones Academy</title>
        <meta name="description" content="Stepping Stones Academy provides a safe and supportive environment for young children to grow, learn, and play." />
      </Helmet>

      <StackHeader
        where="shAbout"
        text={shText}
        image={hdImg}
        alt="Happy children at Stepping Stones Academy - Keller, TX"
      />
      
      <div className={[classes.fullSec, classes.blocksRow].join(" ")}>
        <div className={[classes.inside, classes.blocksWrap].join(" ")}>
          <div className={[classes.block, classes.b1].join(" ")}>
            <div className={[classes.icon, classes.life].join(" ")}><Love /></div>
            <p>Nurturing and safe atmosphere for your child to play and learn</p>
          </div>
          <div className={[classes.block, classes.b3].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><GroupHeart /></div>
            <p>Limited class enrollment to maintain a maximum student/teacher ratio of 13:1, the lowest in the area</p>
          </div>
          <div className={[classes.block, classes.b2].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><Award /></div>
            <p>Lead Teachers with an average of 13 years of preschool teaching experience</p>
          </div>
          <div className={[classes.block, classes.b7].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><Lesson1 /></div>
            <p>ABeka developmental learning curriculum</p>
          </div>
          <div className={[classes.block, classes.b6].join(" ")}>
            <div className={[classes.icon, classes.life].join(" ")}><Shake /></div>
            <p>Christian based character development</p>
          </div>
          <div className={[classes.block, classes.b9].join(" ")}>
            <div className={[classes.icon, classes.school].join(" ")}><Paint /></div>
            <p>Numerous enrichment activities, including art, music, and foreign language</p>
          </div>
          <div className={[classes.block, classes.b4].join(" ")}>
            <div className={[classes.icon, classes.safe].join(" ")}><School /></div>
            <p>Secure and gated campus, with 24-hour video monitoring</p>
          </div>
          <div className={[classes.block, classes.b8].join(" ")}>
            <div className={[classes.icon, classes.safe].join(" ")}><Hail /></div>
            <p>Owner/Director on-site daily</p>
          </div>
          <div className={[classes.block, classes.b5].join(" ")}>
            <div className={[classes.icon, classes.life].join(" ")}><Phone /></div>
            <p>State-of-the-art, Apple iPad available for every student</p>
          </div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.wave1].join(" ")}><TopWave color="color2" /></div>
      <div className={[classes.fullSec, classes.missionFw].join(" ")}>
        {mission}
      </div>
      <div className={[classes.fullSec, classes.wave2].join(" ")}><BtmWave color="color2" /></div>

      <div className={[classes.bgMax, classes.tabMax].join(" ")}>      
        <div className={[classes.fullSec, classes.abTabSec].join(" ")}>
          <AboutTabs />
        </div>
      </div>

      <div className={[classes.fullSec, classes.missionMob].join(" ")}>
        {mission}
      </div>

      <div className={[classes.fullSec, classes.reviewSec].join(" ")}>
        <div className={[classes.insideAlt, classes.inner].join(' ')}>
          <Review review={review1} name="Emily D." addClass="decor" />
        </div>
      </div>
    </div>
  );
}
