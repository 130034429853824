// import React from "react";
import React, {Component} from 'react';
import classes from "./Shared.module.scss";
import styles from "../Header/Header.module.scss";
import appStyles from "../../App.scss";

class Phone extends Component {
  render() {
    let styleParent = classes;
    if (this.props.styleParent === 'styles') {
      styleParent = styles;
    } else if (this.props.styleParent === 'appStyles') {
      styleParent = appStyles;
    }
    return (
      <a 
        className={[styleParent.callLink, styleParent[this.props.where], styleParent[this.props.addClass]].join(' ')} href="tel:+18175624600">
        {this.props.addText ? [this.props.addText] : null} 
        {this.props.br ? <br className={styleParent[this.props.br]} /> : null}
        {this.props.callText ? [this.props.callText] : '817.562.4600'}
      </a>
    )
  }
}
export default Phone;